<template>
    <Title :text="LanguagesLabel" />
    <div>
        <div class="languages-line" v-for="(languages, index) in languages" :key="index">
            <img class="circle" src="@/assets/circle.svg" />
            <!-- <Headline class="":text="interests" /> -->
            <p class="languages-list">{{ languages.language }} - {{ languages.level }}</p>
        </div>
    </div>
</template>

<script>
import Title from "@/components/Title.vue";
//import Headline from "@/components/Headline.vue";

export default {
    Name: "Languages",

    components: { Title },

    computed: {
        currentLanguage() {
            return this.$store.state.currentLanguage;
        },

        LanguagesLabel() {
            return this.currentLanguage.resumeLabels.languages;
        },

        languages() {
            return this.currentLanguage.data.languages;
        },
    },
};
</script>

<style scoped>
.circle {
    width: 6px;
    margin-left: 6px;
    margin-right: 8px;
}

.material-icons {
    display: inline-flex;
    align-items: center;
}

.languages-line {
    display: flex;
}

.languages-list {
    margin-top: 6px;
    margin-bottom: 6px;
}

.contact {
    padding-left: calc(1em + 8px);
    margin: 0.25em;
}
</style>

<template>
    <Title :text="InterestLabel" />
    <div>
        <div class="interest-line" v-for="(interests, index) in interests" :key="index">
            <img class="circle" src="@/assets/circle.svg" />
            <!-- <Headline class="":text="interests" /> -->
            <p class="skills-list">{{ interests }}</p>
        </div>
    </div>
</template>

<script>
import Title from "@/components/Title.vue";
//import Headline from "@/components/Headline.vue";

export default {
    Name: "Interests",

    components: { Title },

    computed: {
        currentLanguage() {
            return this.$store.state.currentLanguage;
        },

        InterestLabel() {
            return this.currentLanguage.resumeLabels.interests;
        },

        interests() {
            return this.currentLanguage.data.interests;
        },
    },
};
</script>

<style scoped>
.circle {
    width: 6px;
    margin-left: 6px;
    margin-right: 8px;
}

.material-icons {
    display: inline-flex;
    align-items: center;
}

.interest-line {
    display: flex;
}

.skills-list {
    margin-top: 6px;
    margin-bottom: 6px;
}

.contact {
    padding-left: calc(1em + 8px);
    margin: 0.25em;
}
</style>

import enData from "../../resume/en-data.yaml";
import frData from "../../resume/fr-data.yaml";

export const languages = [
  {
    name: "Français",
    code: "fr",
    data: frData,
    resumeLabels: {
      contacts: "Contacts",
      profile: "Présentation",
      skills: "Compétences",
      experience: "Expériences",
      professional: "",
      education: "Education",
      interests: "Centres d'interêt",
      languages: "Langues"
    },
  },
  {
    name: "English",
    code: "en",
    data: enData,
    resumeLabels: {
      contacts: "Contacts",
      profile: "Profile",
      skills: "Skills",
      experience: "Experience",
      professional: "",
      education: "Education",
      interests: "Interests",
      languages: "Languages"
    },
  },
];

export function getCurrentLanguage() {
  return languages[0];
}

export function getLanguages() {
  return languages;
}
